import { PlaidItem } from '@/types/PlaidItem'
import { defineStore } from 'pinia'

export const usePlaidItemsStore = defineStore('plaidItems', {
  state: () => ({
    items: [] as PlaidItem[],
  }),
  getters: {
    getItemById: (state) => (itemId: string) => {
      return state.items.find((item) => item.itemId === itemId)
    },
    getBalance: (state) => () => {
      return state.items.reduce((total, item) => {
        if (item.accounts) {
          const balance = item.accounts.reduce((total, account) => {
            if (account.includeInBalance) {
              const balance =
                account.balances.current || account.balances.available || 0
              return account.type === 'credit'
                ? (total -= balance)
                : (total += balance)
            }
            return total
          }, 0)
          return total + balance
        }
        return total
      }, 0)
    },
    getAllAccounts: (state) => () => {
      return state.items.reduce(
        (accounts, item) => {
          if (item.accounts) {
            return [...(accounts || []), ...(item.accounts || [])]
          }
          return accounts
        },
        [] as PlaidItem['accounts'],
      )
    },
    isAccountIncludedInBalance: (state) => (accountId: string) => {
      const item = state.items.find((item) => {
        return item.accounts?.find(
          (account) => account.account_id === accountId,
        )
      })
      if (item) {
        const account = item.accounts?.find(
          (account) => account.account_id === accountId,
        )
        if (account) {
          return account.includeInBalance
        }
      }
      return false
    },
    isAccountCredit: (state) => (accountId: string) => {
      const item = state.items.find((item) => {
        return item.accounts?.find(
          (account) => account.account_id === accountId,
        )
      })
      if (item) {
        const account = item.accounts?.find(
          (account) => account.account_id === accountId,
        )
        if (account) {
          return account.type === 'credit'
        }
      }
      return false
    },
  },
  actions: {
    async getItems() {
      try {
        const response = await this.axios.get(`/items`)
        this.items = response.data.items
      } catch (error) {
        console.error(error)
      }
    },
    async syncTransactionsForItem(itemId: string) {
      try {
        await this.axios.get(`/items/${itemId}/syncTransactions`)
      } catch (error) {
        console.error(error)
      }
    },

    async getAccountsForItem(itemId: string) {
      try {
        const response = await this.axios.get(`/items/${itemId}/getAccounts`)
        const itemIndex = this.items.findIndex((item) => item.itemId === itemId)
        if (itemIndex !== -1) {
          this.items[itemIndex].accounts = response.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async removeItem(itemId: string) {
      try {
        await this.axios.delete(`/items/${itemId}`)
      } catch (error) {
        console.error(error)
      }
    },
    async saveBalance(
      itemId: string,
      accountId: string,
      includeInBalance: boolean,
    ) {
      try {
        await this.axios.post(`/items/${itemId}/${accountId}`, {
          includeInBalance,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
})
