import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabsView from '../views/TabsView.vue'
import { useAuthStore } from '@/stores/auth'
import { Capacitor } from '@capacitor/core'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // redirect: '/auth/login',
    redirect: '/onboarding/add-expenses',
  },
  {
    path: '/pricing',
    meta: { requiresAuth: true },
    component: () =>
      Capacitor.isNativePlatform()
        ? import('@/views/auth/LoginPage.vue')
        : import('@/views/PricingPage.vue'),
  },
  {
    path: '/checkoutSuccess',
    meta: { requiresAuth: true },
    component: () => import('@/views/CheckoutSuccessPage.vue'),
  },
  {
    path: '/profile',
    meta: { requiresAuth: true },
    component: () => import('@/views/ProfilePage.vue'),
  },
  {
    path: '/auth/login',
    component: () => import('@/views/auth/LoginPage.vue'),
  },
  {
    path: '/subscriptionExpired',
    component: () => import('@/views/InactiveSubscriptionPage.vue'),
  },
  {
    path: '/auth/signup',
    component: () => import('@/views/auth/SignupPage.vue'),
  },
  {
    path: '/auth/verify-email',
    component: () => import('@/views/auth/VerifyEmailPage.vue'),
  },
  {
    path: '/onboarding/add-income',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddIncomePage.vue'),
  },
  {
    path: '/onboarding/add-expenses',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddExpensesPage.vue'),
  },
  {
    path: '/onboarding/add-accounts',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddAccountsPage.vue'),
  },
  {
    path: '/onboarding/welcome',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/WelcomePage.vue'),
  },
  {
    path: '/auth/signup-success',
    component: () => import('@/views/auth/SignupSuccessPage.vue'),
  },
  {
    path: '/tabs',
    component: TabsView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/tabs/balance',
      },
      {
        path: 'balance',
        component: () => import('@/views/BalancePage.vue'),
      },
      {
        path: 'category/:id',
        component: () => import('@/views/CategoryPage.vue'),
      },
      {
        path: 'transactions',
        component: () => import('@/views/TransactionsPage.vue'),
      },
      {
        path: 'accounts',
        component: () => import('@/views/AccountsPage.vue'),
      },
      {
        path: 'accounts/:id',
        component: () => import('@/views/AccountDetailsPage.vue'),
        props: true,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (to.path == '/onboarding/add-accounts') {
    return next()
  }
  if (
    to.meta.requiresAuth &&
    !authStore.isLoggedIn &&
    !to.query.hasOwnProperty('session_id')
  ) {
    next({ path: '/auth/login', query: { redirect: to.fullPath } })
  } else if (to.meta.requiresAuth) {
    if (to.path == '/pricing' || to.path == '/onboarding/add-accounts') {
      if (Capacitor.isNativePlatform()) {
        return next('/tabs/balance')
      }
      return next()
    }
    // if (
    //   !to.fullPath.includes('onboarding') &&
    //   authStore.user?.stripeSubscriptionStatus !== 'active' &&
    //   authStore.user?.stripeSubscriptionStatus !== 'trialing'
    // ) {
    //   console.log(to)
    //   if (Capacitor.isNativePlatform()) {
    //     return next('/subscriptionExpired')
    //   }
    //   return next('/pricing')
    // }
    return next()
  } else {
    next()
  }

  // TODO: Implement refactor from AI, Make sure auth state is fully hydrated before the router hook some how
})

export default router
