import { User } from './../types/user'
import { defineStore } from 'pinia'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'
import { toRaw } from 'vue'
import { usePlaidItemsStore } from './plaidItem'
import { useMessagesStore } from './messages'

const userPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_USER_POOL_ID,
  ClientId: import.meta.env.VITE_USER_POOL_APP_CLIENT_ID,
})

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    refreshToken: null as string | null,
    user: null as User | null,
    interceptor: null as number | null,
    onboardingComplete: true,
  }),
  getters: {
    isLoggedIn: (state) => state.token !== null,
    hasActiveSubscription: (state) => {
      return (
        state.user?.stripeSubscriptionStatus === 'active' ||
        state.user?.stripeSubscriptionStatus === 'trialing'
      )
    },
    hasExpiredSubscription: (state) => {
      return (
        state.user?.stripeSubscriptionStatus != undefined &&
        !(
          state.user?.stripeSubscriptionStatus === 'active' ||
          state.user?.stripeSubscriptionStatus === 'trialing'
        )
      )
    },
    canAddAccount: (state) => {
      const itemsStore = usePlaidItemsStore()

      const userStripePriceId = state.user?.stripePriceId

      const accountLimits: Record<string, number> = {
        [import.meta.env.VITE_STRIPE_PRICE_STANDARD]: 3,
        [import.meta.env.VITE_STRIPE_PRICE_PRO]: 10,
        [import.meta.env.VITE_STRIPE_PRICE_SUPPORTER]: 20,
      }

      let limit = 1

      if (
        userStripePriceId &&
        ['active', 'trialing'].includes(
          state.user?.stripeSubscriptionStatus ?? '',
        )
      ) {
        limit = accountLimits[userStripePriceId]
      }
      console.log(limit, state.user?.stripeSubscriptionStatus)

      return itemsStore.items.length < limit
    },
  },
  actions: {
    async login(username: string, password: string) {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      })

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })

      return await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (result: CognitoUserSession) => {
            this.token = result.getIdToken().getJwtToken()
            this.refreshToken = result.getRefreshToken().getToken()
            this.setAuthHeader()
            this.setInterceptor()
            await this.getUser()
            this.amplitude.setUserId(this.user?.cognitoId)
            resolve(this.token)
          },

          onFailure: (err) => {
            alert(err.message || JSON.stringify(err))
            reject()
          },
        })
      })
    },
    async getUser() {
      try {
        let user = await this.axios.get(`/user`)
        this.user = new User(
          user.data.email,
          user.data.firstName,
          user.data.lastName,
          user.data.cognitoId,
          user.data.stripeCustomerId,
          user.data.stripeSubscriptionId,
          user.data.stripeSubscriptionStatus,
          user.data.stripePriceId,
        )
        await this.ionicStorage.set('user', toRaw(this.user))
        await this.ionicStorage.set('token', this.token)
        await this.ionicStorage.set('refreshToken', this.refreshToken)
        return user.data
      } catch (error) {
        console.error(error)
      }
    },
    async createCheckoutSession(priceId: string) {
      try {
        let session = await this.axios.post(`/users/createCheckoutSession`, {
          priceId,
        })
        return session.data
      } catch (error) {
        console.error(error)
      }
    },
    async signup(
      firstName: string,
      lastName: string,
      username: string,
      password: string,
    ) {
      return await new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          [
            new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
            new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
          ],
          [],
          (err, result) => {
            if (err) {
              alert(err.message || JSON.stringify(err))
              reject()
            } else {
              this.user = new User(username)
              this.onboardingComplete = false
              resolve(result)
            }
          },
        )
      })
    },
    async confirmRegistration(code: string) {
      if (!this.user) {
        alert('No user')
        return
      }
      const cognitoUser = new CognitoUser({
        Username: this.user.email,
        Pool: userPool,
      })

      return await new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (err) {
            alert(err.message || JSON.stringify(err))
            reject()
          } else {
            resolve(result)
          }
        })
      })
    },
    async logout() {
      const cognitoUser = userPool.getCurrentUser()
      if (cognitoUser) {
        cognitoUser.signOut()
      }
      this.token = null
      this.user = null
      this.axios.defaults.headers.common['Authorization'] = false
      this.interceptor
        ? this.axios.interceptors.response.eject(this.interceptor)
        : null
      await this.ionicStorage.remove('user')
      await this.ionicStorage.remove('token')
      this.router.push('/auth/login')
    },
    setAuthHeader() {
      this.axios.defaults.headers.common['Authorization'] = this.token
    },
    async loadRefeshToken() {
      this.refreshToken = await this.ionicStorage.get('refreshToken')
      this.user = await this.ionicStorage.get('user')
    },
    async refreshSession() {
      let cognitoUser: any
      cognitoUser = userPool.getCurrentUser()

      if (!cognitoUser) {
        if (!this.user || !this.user.email) {
          console.log('No user')
          return
        }
        cognitoUser = new CognitoUser({
          Username: this.user.email,
          Pool: userPool,
        })
      }
      if (!this.refreshToken) {
        console.error('No refresh token')
      }

      const refreshToken = new CognitoRefreshToken({
        RefreshToken: this.refreshToken,
      })
      return await new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (err) {
            console.log('Error refreshing session', err)
            this.logout()
            reject(err)
          } else {
            this.token = session.getIdToken().getJwtToken()
            this.setAuthHeader()
            resolve(session)
          }
        })
      })
    },
    //   setInterceptor() {
    //     this.interceptor = this.axios.interceptors.response.use(
    //       (response) => response,
    //       (error) => {
    //         if (!error.response) {
    //           this.logout()
    //           this.router.push('/')
    //         }
    //       },
    //     )
    //   },
    // },
    setInterceptor() {
      this.interceptor = this.axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error) {
            if (error.response && error.response.status === 500) {
              return Promise.reject(error)
            }
            try {
              const session = await this.refreshSession()
              // Retry the original request with the new token
              return this.axios({
                ...error.config,
                headers: { Authorization: session.getIdToken().getJwtToken() },
              })
            } catch (refreshError) {
              // If refresh token is expired or any other error occurs, logout
              this.logout()
              this.router.push('/auth/login')
            }
          }
          return Promise.reject(error)
        },
      )
    },
  },
})
